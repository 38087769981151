import { useRouter } from 'next/router';
import { useAppContext } from '../../../hooks/useAppContext';
import { StyledLayout } from '@/components/Layouts/App/styles';
import { ILayout } from '@/components/Layouts/types';

export const AppLayout = ({ dataTestId, children }: ILayout) => {
  const router = useRouter();
  const { isShowingPreApprovedBanner } = useAppContext();
  const isProductDescriptionPage = router?.asPath.includes('products/');

  return (
    <StyledLayout
      data-testid={dataTestId}
      $isProductDescriptionPage={isProductDescriptionPage}
      $isShowingPreApprovedBanner={isShowingPreApprovedBanner}
    >
      {children}
    </StyledLayout>
  );
};
