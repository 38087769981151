import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2 12h20m-9.333 9.333L22 12l-9.333-9.333"
    />
  </svg>
);
export const IconArrowRight = memo(SvgIconArrowRight);
