import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconCrossMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      d="M12.707 13.454 4.22 21.94l-1.414-1.415 8.486-8.485zm8.486-8.484-8.485 8.485-1.414-1.414 8.485-8.486z"
    />
    <path fill="currentColor" d="M11.293 13.454 2.808 4.97l1.414-1.414 8.485 8.485z" />
    <path fill="currentColor" d="m19.778 21.94-8.485-8.485 1.414-1.415 8.485 8.486z" />
  </svg>
);
export const IconCrossMenu = memo(SvgIconCrossMenu);
