import { IconCrossMenu } from 'uibook-icons/solid/IconCrossMenu';
import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';

type DrawerHeaderProps = React.ComponentPropsWithoutRef<'div'> & {
  /** The title of the component */
  title: string;
  /**
   * Pass Tailwind classNames to change the colour of the `title` and the `icon`
   *
   * Example, blue background with white text:
   *
   * ```tsx
   * <DrawerHeader className="bg-blue-500 text-white" />;
   * ```
   *
   * Example, white background with charcoal text:
   *
   * ```tsx
   * <DrawerHeader className="text-charcoal-500 bg-white" />;
   * ```
   */
  className?: string;
  /** Callback fired when the component requests to be closed. */
  onClose: (event: React.MouseEvent) => void;
  /** Props to pass to the Typography component, which wraps the `title` */
  typographyProps?: React.ComponentPropsWithoutRef<typeof Typography>;
  /** Props to pass to the button element, which wraps the `X` icon */
  buttonProps?: React.ComponentPropsWithoutRef<'button'>;
};

export const DrawerHeader = ({
  title,
  className,
  onClose,
  typographyProps,
  buttonProps,
  ...wrapperProps
}: DrawerHeaderProps) => {
  return (
    <div className={cn('flex items-center justify-between px-6 py-7', className)} {...wrapperProps}>
      <Typography bold {...typographyProps}>
        {title}
      </Typography>
      <button aria-label="Close Drawer" onClick={onClose} {...buttonProps}>
        <IconCrossMenu className="w-6 transition-opacity duration-150 hover:opacity-50 focus:opacity-50" />
      </button>
    </div>
  );
};
