let parsedCookies: Record<string, string> | undefined = undefined;
export function trackingDenied(): boolean {
  if (!parsedCookies) {
    parsedCookies =
      typeof document === 'undefined'
        ? {}
        : Object.fromEntries(
            document.cookie.split('; ').map((v) => v.split(/=/).map(decodeURIComponent)),
          );
  }

  return parsedCookies?.raylo_trackingDenied === 'true';
}

export function clearTrackingCache() {
  parsedCookies = undefined;
}
