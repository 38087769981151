import animationData from './animation.json';
import { px2Rem } from '../../../utils/px2Rem';
import LottieAnimation from '../../LottieAnimation';
import { useNavBarContext } from '../NavBarContext/NavBarContext';
import { getActiveAccountSlug } from '../../../utils/getAccountSlug';

type AnimatedLogoProps = {
  dataTestId?: string;
};

const AnimatedLogo = ({ dataTestId }: AnimatedLogoProps) => {
  const { consumerIsBusiness, loggedInData, app } = useNavBarContext();
  const href = `https://raylo.com${consumerIsBusiness ? '/business' : ''}`;

  /** Direct users from frontend app or products app to account overview if logged in */
  const loggedInHref =
    loggedInData?.activeAccount && app === 'account'
      ? `/account/overview/${getActiveAccountSlug(loggedInData.activeAccount)}`
      : `${process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}/account`;

  return (
    <a
      href={loggedInData ? loggedInHref : href}
      title="Raylo"
      style={{ width: px2Rem(132) }}
      data-testid={dataTestId}
    >
      <LottieAnimation animationData={animationData} height={32} width={132} />
    </a>
  );
};

export default AnimatedLogo;
