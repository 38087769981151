import { PropsWithChildren } from 'react';
import { ConsumerTypesEnum, GlobalUIStyles, NavDataModel } from 'uibook';
import { CustomGrowthBookProvider } from './CustomGrowthBookProvider';
import { CookiesProvider } from 'react-cookie';
import { RayloCookiesProvider } from '@/hooks/useRayloCookiesContext';
import { RayloIFrameProvider } from '@/hooks/useRayloIFrameContext';
import { AuthTokenContextProvider } from '@/hooks/useAuthTokenContext';
import { IntercomProvider } from 'react-use-intercom';
import { CustomApolloProvider } from './CustomApolloProvider';
import { ConsumerTypeProvider } from '@/hooks/useConsumerTypeContext';
import { CustomerProvider } from '@/hooks/useCustomerContext';
import { AppProvider } from '@/hooks/useAppContext';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import GlobalNavBar from '@/components/GlobalNavBar/GlobalNavBar';
import AppLayout from '@/components/Layouts/App';

export type RayloAppProps = {
  /**
   * `navData` is fetched in `getStaticProps` in the PLP and PDP pages, and is then and passed to
   * the `_app.tsx` component via the `pageProps`.
   *
   * `consumerType` is currently only set PDP and PLP pages. Any other pages, it will default to
   * `ConsumerTypesEnum.PERSONAL`.
   */
  navData?: NavDataModel;
  consumerType?: ConsumerTypesEnum;
};

export const GlobalAppProvider = ({
  navData,
  consumerType,
  children,
}: PropsWithChildren<RayloAppProps>) => {
  return (
    <CustomGrowthBookProvider>
      <CookiesProvider>
        <RayloCookiesProvider>
          <RayloIFrameProvider>
            <AuthTokenContextProvider>
              <IntercomProvider
                appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
                initializeDelay={2000}
              >
                <CustomApolloProvider>
                  <ConsumerTypeProvider consumerType={consumerType}>
                    <CustomerProvider>
                      <AppProvider>
                        <StyleSheetManager shouldForwardProp={isPropValid}>
                          <GlobalUIStyles
                            themeName={
                              process.env.NEXT_PUBLIC_THEME
                                ? (process.env.NEXT_PUBLIC_THEME as React.ComponentProps<
                                    typeof GlobalUIStyles
                                  >['themeName'])
                                : undefined
                            }
                          />
                          <GlobalNavBar navData={navData ?? { categories: [] }} />
                          <AppLayout>{children}</AppLayout>
                        </StyleSheetManager>
                      </AppProvider>
                    </CustomerProvider>
                  </ConsumerTypeProvider>
                </CustomApolloProvider>
              </IntercomProvider>
            </AuthTokenContextProvider>
          </RayloIFrameProvider>
        </RayloCookiesProvider>
      </CookiesProvider>
    </CustomGrowthBookProvider>
  );
};
