import { useNavBarContext } from '../../navbar/NavBarContext/NavBarContext';

export const useGlobalSearchTracking = () => {
  const { track } = useNavBarContext();

  const trackSearchEvent = (searchTerm: string) => {
    track('Products Searched', {
      query: searchTerm,
    });
  };

  return {
    trackSearchEvent,
  };
};
