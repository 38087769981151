import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconTablet = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.364 1H4.636C3.733 1 3 1.657 3 2.467v19.066C3 22.343 3.733 23 4.636 23h14.728c.903 0 1.636-.657 1.636-1.467V2.467C21 1.657 20.267 1 19.364 1"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.38 19a.38.38 0 0 0-.35.524.37.37 0 0 0 .35.226.36.36 0 0 0 .345-.227.4.4 0 0 0 .025-.143.37.37 0 0 0-.37-.38"
    />
  </svg>
);
export const IconTablet = memo(SvgIconTablet);
