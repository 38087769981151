import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.801 2H7.198c-.96 0-1.74.779-1.74 1.74v16.52c0 .961.78 1.74 1.74 1.74h9.603c.96 0 1.74-.779 1.74-1.74V3.74c0-.961-.78-1.74-1.74-1.74"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.685 4.935h-5.37a.94.94 0 0 1-.932-.8L8.068 2h7.865l-.316 2.133a.94.94 0 0 1-.933.802"
    />
  </svg>
);
export const IconPhone = memo(SvgIconPhone);
