import constate from 'constate';
import { useRayloCookiesContext } from './useRayloCookiesContext';
import { useQuery } from '@apollo/client';
import { useConsumerTypeContext } from './useConsumerTypeContext';
import { useAuthTokenContext } from './useAuthTokenContext';
import { userIsLoggedIn } from '@/utils/auth/userAuth';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { isNativeAppWebView } from 'uibook/utils/isNativeAppWebView';
import { getActiveAccountSlug } from 'uibook';
import { GET_CUSTOMER_DATA } from '@/graphql/operations/getCustomerData.graphql';
import { BannerType } from '../../../../packages/uibook/src/components/navbar/Navbar.types';

const useCustomer = () => {
  const { authToken } = useAuthTokenContext();
  const { consumerMoneyField } = useConsumerTypeContext();
  const router = useRouter();
  const { cookieValues, setDomainCookie } = useRayloCookiesContext();
  const {
    raylo_checkoutContext: cookieCheckoutContext,
    raylo_subscriptionId: cookieSubscriptionId,
  } = cookieValues;

  const hasAuthenticatedUser = useMemo(() => userIsLoggedIn(authToken), [authToken]);
  const checkoutContext = hasAuthenticatedUser ? cookieCheckoutContext : undefined;
  const subscriptionId = hasAuthenticatedUser ? cookieSubscriptionId : undefined;

  const { data: customerData, loading: isCustomerDataLoading } = useQuery(GET_CUSTOMER_DATA, {
    skip: !hasAuthenticatedUser,
  });

  const matchingSubscription = useMemo(
    () =>
      customerData?.customer?.orders
        ?.flatMap((order) => order.items)
        .find((item) => item.subscription?.id === subscriptionId)?.subscription,
    [customerData, subscriptionId],
  );

  const preApproval = customerData?.customer?.preApproval;
  const preApprovalCheckoutToken = preApproval?.checkout?.token;
  const upgradeCheckoutToken = matchingSubscription?.upgrade?.checkout?.token;

  // the upgrade token is used if the subscription ID exists, otherwise use the preapproval token
  const checkoutToken = subscriptionId ? upgradeCheckoutToken : preApprovalCheckoutToken;

  const isUpgrading = !!subscriptionId;
  const isAddingNewBusiness = checkoutContext === 'ADD_NEW_BUSINESS';
  const isMobileApp = isNativeAppWebView();

  /** Check if there are any orders in arrears */
  const orderInArrears = useMemo(
    () =>
      customerData?.customer?.orders.find(
        (order) => (order?.items[0]?.subscription?.arrearsAmount?.valueInSubunit ?? 0) > 0,
      ),
    [customerData],
  );

  /** Check if there are any upgradable orders using upgrade.eligible or existing upgrade checkout */
  const orderInUpgrade = useMemo(
    () =>
      customerData?.customer?.orders.find(
        (order) =>
          order?.items[0]?.subscription?.upgrade.eligible ||
          order?.items[0]?.subscription?.upgrade.checkout,
      ),
    [customerData],
  );
  /**
   * Due to how Next.js builds the server-generated content, and how the component tree is hydrated,
   * we should default the `hasLoggedInCustomer` and `inArrears` value to `false` to prevent a
   * hydration issue. Then, client-side, we can update the state to reflect the actual value, which
   * doesn't cause any hydration issues.
   */
  const [hasLoggedInCustomer, setHasLoggedInCustomer] = useState<boolean>(false);
  const [inArrears, setInArrears] = useState<boolean>(false);
  const [isAddingTech, setIsAddingTech] = useState<boolean>(false);

  useEffect(() => {
    setHasLoggedInCustomer(hasAuthenticatedUser || isMobileApp);
  }, [hasAuthenticatedUser, isMobileApp]);

  useEffect(() => {
    setInArrears(hasAuthenticatedUser && !!orderInArrears);
  }, [hasAuthenticatedUser, orderInArrears]);

  useEffect(() => {
    setIsAddingTech(!isUpgrading && !isAddingNewBusiness);
  }, [setIsAddingTech, isUpgrading, isAddingNewBusiness]);

  const accountSwitcherData = useMemo(() => {
    if (!customerData?.customer) {
      return null;
    }

    const accountsOnClick = (
      accountName: string,
      accountId: string,
      accountIsBusiness: boolean,
    ) => {
      if (!isAddingTech) {
        window.location.href = `${process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}/account/overview/${accountName}`;
        return;
      }

      setDomainCookie('activeAccountId', accountId);

      const slug = (() => {
        if (router.query?.product) {
          return `${accountIsBusiness ? '/business' : ''}/products/${router.query.product}?term=${router.query.term}`;
        } else {
          return `${accountIsBusiness ? '/business' : ''}/products`;
        }
      })();
      router.push(slug);
    };

    const organizations = customerData.customer.organizations.map((org) => ({
      id: org.id,
      name: org.name,
      isBusiness: true,
      onClick: () => accountsOnClick(org.name.replaceAll(' ', '-').toLowerCase(), org.id, true),
    }));

    const name = `${customerData.customer.firstName} ${customerData.customer.lastName}`;

    const personalAccount = {
      name,
      id: customerData.customer.id,
      isBusiness: false,
      onClick: () => accountsOnClick('personal', customerData?.customer?.id ?? '', false),
    };

    const hasPersonalOrders = customerData.customer.orders.some((order) => !order.organization);

    return {
      customerName: `${customerData?.customer?.firstName} ${customerData?.customer?.lastName}`,
      email: customerData.customer.email,
      accounts: [...(hasPersonalOrders ? [personalAccount] : []), ...organizations],
    };
  }, [customerData, setDomainCookie, isAddingTech, router]);

  const activeAccount = useMemo(
    () =>
      accountSwitcherData?.accounts.find(
        (account) => account.id === cookieValues?.raylo_activeAccountId,
      ),
    [accountSwitcherData, cookieValues],
  );

  const accountBaseUrl = process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL;

  const itemInArrearsPaymentSlug =
    orderInArrears && activeAccount
      ? `${accountBaseUrl}/account/${getActiveAccountSlug(activeAccount)}/payment/${orderInArrears.items[0].subscription?.id}`
      : undefined;

  const bannerToShow: BannerType = inArrears ? 'arrears' : orderInUpgrade ? 'upgrade' : null;

  if (!hasAuthenticatedUser) {
    return {
      isCustomerDataReady: true,
      isMobileApp,
      isUpgrading: false,
      isAddingTech: false,
      isAddingNewBusiness: false,
      subscriptionId: undefined,
      hasLoggedInCustomer: false,
      hasAuthenticatedUser: false,
      checkoutToken: undefined,
      formattedPreApprovedAmount: undefined,
      preApprovedAmount: undefined,
      accountSwitcherData: undefined,
    };
  }

  return {
    isCustomerDataReady: !hasAuthenticatedUser || isCustomerDataLoading === false,
    isMobileApp,
    isAddingTech,
    isUpgrading,
    isAddingNewBusiness,
    deviceUpgrading:
      orderInUpgrade?.items[0]?.subscription?.activeAsset?.variant?.product.displayName,
    inArrears,
    itemInArrearsPaymentSlug,
    subscriptionId,
    checkoutContext,
    hasLoggedInCustomer,
    checkoutToken,
    formattedPreApprovedAmount:
      preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.formattedValue,
    preApprovedAmount: preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.value,
    accountSwitcherData,
    activeAccount,
    bannerToShow,
  };
};

const [CustomerProvider, useCustomerContext] = constate(useCustomer);
export { CustomerProvider, useCustomerContext };
