import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7 7a5 5 0 1 0 10 0A5 5 0 0 0 7 7M3 22a9 9 0 0 1 18 0z"
    />
  </svg>
);
export const IconAccount = memo(SvgIconAccount);
