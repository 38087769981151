import { StyledNavBar, StyledNavBarWrapper } from './NavBarWrapper.styles';
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo';
import TrustPilotNavImg from '../svgs/trustpilot-navbar';
import { useState, useEffect, useCallback, useRef } from 'react';
import { SideNav } from '../SideNav/SideNav';
import { BurgerNav } from '../utils/utilComponents';
import { helpSubMenuLinks, categoryForUrl, aboutSubMenuLinks } from '../utils/utils';
import { DropdownSubMenu } from '../DropdownSubMenu/DropdownSubMenu';
import CalloutCarousel from '../../CalloutCarousel/CalloutCarousel';
import { NavBarContextProvider, useNavBarContext } from '../NavBarContext/NavBarContext';
import { NavBarConsumerToggle } from '../NavBarConsumerToggle/NavBarConsumerToggle';
import { TAILWIND_BREAKPOINTS } from '../../../constants';
import { LoginPrompt } from '../LoginPrompt/LoginPrompt';
import { cn } from '../../../utils/cn';
import { DesktopLinks } from '../DesktopLinks/DesktopLinks';
import { GlobalSearch } from '../../GlobalSearch/GlobalSearch';
import { IconSearch } from 'uibook-icons/solid/IconSearch';
import { NavBarFakeSearchField } from '../NavBarFakeSearchField/NavBarFakeSearchField';

export const NavBarWrapper = (props: React.ComponentProps<typeof NavBarContextProvider>) => {
  return (
    <NavBarContextProvider {...props}>
      <NavBarWrapperWithContext />
    </NavBarContextProvider>
  );
};

const NavBarWrapperWithContext = () => {
  const {
    navData,
    accountHomeUrl,
    productsUrl,
    LinkComponent,
    sideNavOpen,
    setSideNavOpen,
    subMenuOpen,
    setSubMenuOpen,
    handleCloseSideNav,
    consumerIsBusiness,
    loggedInData,
    bannerToShow,
    app,
    isUpgrading,
    removeDomainCookie,
    searchIsOpen,
    setSearchIsOpen,
    track,
  } = useNavBarContext();

  const navRef = useRef<HTMLDivElement>(null);
  const calloutRef = useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const [calloutHeight, setCalloutHeight] = useState(0);
  const [showSearchCtaInHeader, setShowSearchCtaInHeader] = useState(false);
  const oldScrollY = useRef(0);
  const SCROLL_THRESHOLD = 60;
  const [upgradeExitModalOpen, setUpgradeExitModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [displayUpgradeModal, setDisplayUpgradeModal] = useState(false);

  const callouts = [
    <p key="callout-1">Raylo is authorised and regulated by the Financial Conduct Authority</p>,
    <p key="callout-2">As seen on TV</p>,
    <p key="callout-3">Join over 100,000 subscribers</p>,
  ];

  const handleScroll = useCallback(() => {
    if (window.scrollY < SCROLL_THRESHOLD) {
      setScrollDirection('down');
      setCalloutHeight(0);
      setShowSearchCtaInHeader(false);
      return;
    }
    if (window.scrollY > oldScrollY.current) {
      setScrollDirection('up');
    } else {
      setScrollDirection('down');
    }
    if (window.scrollY > SCROLL_THRESHOLD) {
      setCalloutHeight(calloutRef.current?.getBoundingClientRect().height ?? 0);
      setShowSearchCtaInHeader(true);
    }
    oldScrollY.current = window.scrollY;
  }, []);

  useEffect(() => {
    if (!navRef.current) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (!entry) return;

      if (entry.contentRect.width < TAILWIND_BREAKPOINTS.lg) {
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    });

    observer.observe(navRef.current);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [handleScroll]);

  /** Update displayUpgradeModal state client-side to prevent hydration error */
  useEffect(() => {
    setDisplayUpgradeModal(!!isUpgrading && app === 'products');
  }, [isUpgrading, app]);

  const handleUpgradeExitModalOpen = (name: string) => {
    setSelectedItem(name);
    setUpgradeExitModalOpen(true);
  };

  const exitUpgrade = () => {
    setUpgradeExitModalOpen(false);
    if (selectedItem === 'burger-nav') {
      setSideNavOpen('product');
      removeDomainCookie?.('subscriptionId');
      return;
    }
    if (selectedItem) {
      removeDomainCookie?.('subscriptionId');
      window.location.assign(`${productsUrl}${categoryForUrl(selectedItem?.toLowerCase())}`);
    }
    setSelectedItem(null);
  };

  const handleClickSearchCta = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!searchIsOpen) {
      track('Search CTA Clicked', {});
    }
    setSearchIsOpen(!searchIsOpen);
  };

  return (
    <>
      <StyledNavBarWrapper
        data-testid="navbar-wrapper"
        className="bg-tv-navbar"
        $calloutHeight={calloutHeight}
        $hideNav={scrollDirection === 'up'}
        ref={navRef}
      >
        {!loggedInData && <CalloutCarousel callouts={callouts} ref={calloutRef} />}
        <StyledNavBar className="flex w-full items-center px-4 py-6">
          <AnimatedLogo />
          {!loggedInData && <TrustPilotNavImg className="trustpilot-nav-img shrink-0" />}

          <div className="max-w-96 mx-8 hidden w-full lg:block">
            <NavBarFakeSearchField onClick={handleClickSearchCta} />
          </div>

          <div className="nav-primary-buttons shrink-0">
            <div className="quick-links">
              <button
                onClick={handleClickSearchCta}
                aria-label="Open Search panel"
                className={cn(
                  'global-search-trigger rounded-full bg-transparent p-2 transition-opacity duration-300 hover:bg-blue-400 focus:bg-blue-400 lg:hidden',
                  {
                    'opacity-100': showSearchCtaInHeader,
                    'opacity-0': !showSearchCtaInHeader,
                  },
                )}
                data-testid="search-cta"
              >
                <IconSearch className="w-6 text-white" />
              </button>

              {!loggedInData && (
                <DropdownSubMenu
                  title="About"
                  items={aboutSubMenuLinks}
                  LinkComponent={LinkComponent}
                />
              )}
              <DropdownSubMenu title="Help" items={helpSubMenuLinks} />
              {loggedInData?.activeAccount && (
                <button
                  className={cn(
                    'my-account relative h-6 w-6 rounded-full bg-white font-bold text-blue-500',
                    {
                      "after:absolute after:right-0 after:top-0 after:h-2 after:w-2 after:rounded-full after:border after:border-white after:content-['']":
                        !!bannerToShow,
                      'after:bg-error-500': bannerToShow === 'arrears',
                      'after:bg-success-500': bannerToShow === 'upgrade',
                    },
                  )}
                  onClick={() => setSideNavOpen((prev) => (prev ? null : 'account'))}
                  data-testid="my-account-button"
                >
                  {loggedInData.activeAccount.name[0]}
                </button>
              )}
              {!loggedInData && (
                <a
                  className="text-link"
                  href={`https://www.raylo.com/${consumerIsBusiness ? 'business' : ''}#how-it-works`}
                  title="How it works"
                >
                  How it works
                </a>
              )}
              {!loggedInData && <NavBarConsumerToggle location="topNav" />}

              {!loggedInData && <LoginPrompt dataTestId="login-prompt-nav" />}

              <BurgerNav
                dataTestId="burger-mobile"
                onClick={() =>
                  displayUpgradeModal
                    ? handleUpgradeExitModalOpen('burger-nav')
                    : setSideNavOpen('product')
                }
                sideNavOpen={sideNavOpen}
              />
            </div>
          </div>
        </StyledNavBar>
        <DesktopLinks
          navData={navData}
          LinkComponent={LinkComponent}
          setUpgradeExitModalOpen={setUpgradeExitModalOpen}
          upgradeExitModalOpen={upgradeExitModalOpen}
          sideNavOpen={sideNavOpen}
          exitUpgrade={exitUpgrade}
          handleUpgradeExitModalOpen={handleUpgradeExitModalOpen}
          displayUpgradeModal={displayUpgradeModal}
          setSideNavOpen={setSideNavOpen}
          productsUrl={productsUrl}
        />
      </StyledNavBarWrapper>

      <div
        className={cn('absolute left-0 w-full bg-blue-500 px-4 lg:hidden', {
          'top-[140px] sm:top-[123px]': !loggedInData,
          'top-[88px]': loggedInData,
        })}
      >
        <NavBarFakeSearchField onClick={handleClickSearchCta} />
      </div>

      <SideNav
        isOpen={sideNavOpen === 'product'}
        onClose={handleCloseSideNav}
        navData={navData}
        subMenuOpen={subMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        setSideNavOpen={setSideNavOpen}
        accountHomeUrl={accountHomeUrl}
        productsUrl={productsUrl}
        isLoggedIn={!!loggedInData}
        LinkComponent={LinkComponent}
        desktopSide="left"
      />
      <SideNav
        desktopSide="right"
        isOpen={sideNavOpen === 'account'}
        onClose={handleCloseSideNav}
        subMenuOpen={subMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        setSideNavOpen={setSideNavOpen}
        accountHomeUrl={accountHomeUrl}
        productsUrl={productsUrl}
        LinkComponent={LinkComponent}
        loggedInData={loggedInData}
        bannerToShow={bannerToShow}
      />
      <GlobalSearch />
    </>
  );
};
