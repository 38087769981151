import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22.547 12.703V6.375h-6.328"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m22.547 6.375-7.443 7.443a1.406 1.406 0 0 1-1.99 0l-2.932-2.933a1.407 1.407 0 0 0-1.99 0l-6.739 6.74"
    />
  </svg>
);
export const IconGraph = memo(SvgIconGraph);
