import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.389 12.775a7.158 7.158 0 1 0 13.175-5.6 7.158 7.158 0 0 0-13.175 5.6m11.648 2.261 5.552 5.552"
    />
  </svg>
);
export const IconSearch = memo(SvgIconSearch);
