import { forwardRef, type ForwardedRef } from 'react';
import {
  StyledAccountListItem,
  StyledAccountsList,
  StyledAccountsMenu,
} from './AccountSwitcherDropdown.styles';
import { px2Rem } from '../../utils/px2Rem';
import type { Account } from '../../types/navData.types';
import { Typography } from '../../foundations/Typography/Typography';

type AccountSwitcherDropdownProps = {
  dataTestId?: string;
  activeAccount: Account;
  accounts: Account[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  closeSideNav: () => void;
};

const AccountSwitcherDropdown = forwardRef(function AccountSwitcherDropdown(
  {
    dataTestId,
    accounts,
    activeAccount,
    isOpen,
    setIsOpen,
    closeSideNav,
  }: AccountSwitcherDropdownProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const changeAccount = (accountOnClick: () => void) => {
    setIsOpen(false);
    accountOnClick();
    closeSideNav();
  };

  return (
    <StyledAccountsMenu
      ref={ref}
      data-testid={`${dataTestId}-accounts-menu`}
      $height={(accounts.length + 1) * 64}
      $active={isOpen}
    >
      <Typography bold style={{ padding: px2Rem(24), margin: 0 }}>
        Accounts
      </Typography>
      <StyledAccountsList>
        {accounts.map((account, index) => (
          <StyledAccountListItem
            key={index}
            $active={account.id === activeAccount.id}
            $isBusiness={account.isBusiness}
            $letter={account.name?.[0] ?? ''}
            onClick={() => changeAccount(account.onClick)}
            data-testid={`${dataTestId}-account-${index}-${account.isBusiness ? 'business' : 'personal'}`}
          >
            <Typography
              data-testid={`${dataTestId}-name${account.id === activeAccount.id ? '-active' : ''}`}
              variant="body2"
              medium
              className="w-max"
            >
              {account.name}
            </Typography>
          </StyledAccountListItem>
        ))}
      </StyledAccountsList>
    </StyledAccountsMenu>
  );
});

export default AccountSwitcherDropdown;
