import { Configure, Index, useHits } from 'react-instantsearch';
import { Typography } from '../../foundations/Typography/Typography';
import { IconGraph } from 'uibook-icons/solid/IconGraph';
import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { SEARCH_INDEX_SEARCH_TERM_QUERIES } from '../../utils/search';
import { SearchSearchTermQuery } from '../../types/search.types';
import { useGlobalSearchTracking } from './hooks/useGlobalSearchTracking';

export const GlobalSearchTrendingInner = () => {
  const { handleCloseSearchOverlay, LinkComponent, getSearchQueryUrl } = useNavBarContext();
  const { trackSearchEvent } = useGlobalSearchTracking();
  const { items } = useHits<SearchSearchTermQuery>();
  const filteredItems = items.filter((hit) => !['', '*'].includes(hit.q));

  if (filteredItems.length === 0) {
    return null;
  }

  const handleClickTrendingItem = (searchTerm: string) => {
    trackSearchEvent(searchTerm);
    handleCloseSearchOverlay();
  };

  const Link = LinkComponent || 'a';

  return (
    <div className="-mx-6 flex flex-col gap-4 overflow-hidden" data-testid="GlobalSearchTrending">
      <Typography bold className="pl-6">
        Trending searches
      </Typography>
      <div className="overflow-x-scroll [&::-webkit-scrollbar]:hidden">
        <div className="flex gap-3 px-6">
          <IconGraph className="text-charcoal-500 w-6 flex-shrink-0" />
          {filteredItems.map((item) => (
            <Link
              key={item.id}
              href={getSearchQueryUrl(item.q)}
              className="text-charcoal-400 bg-charcoal-100 whitespace-nowrap rounded px-2 py-1"
              onClick={() => handleClickTrendingItem(item.q)}
              data-testid={`GlobalSearchTrending-${item.id}`}
            >
              {item.q}
            </Link>
          ))}
          {/** Spacer element so that the right-hand side of the scroll area doesn't look terrible */}
          <span className="block w-2 flex-shrink-0" />
        </div>
      </div>
    </div>
  );
};

export const GlobalSearchTrending = () => {
  return (
    <Index indexName={SEARCH_INDEX_SEARCH_TERM_QUERIES}>
      <Configure query="*" />
      <GlobalSearchTrendingInner />
    </Index>
  );
};
