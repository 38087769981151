import { useEffect, useState, useRef, useCallback } from 'react';
import { DropdownSubMenuProps } from '../Navbar.types';
import { useNavBarContext } from '../NavBarContext/NavBarContext';
import { NavLink } from '../utils/utilComponents';
import { StyledDropdownSubMenu } from './DropdownSubMenu.styles';

export const DropdownSubMenu = ({
  title,
  items,
  dataTestId,
  LinkComponent,
}: DropdownSubMenuProps) => {
  const { suffixUrlsWithConsumerType } = useNavBarContext();
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleAccountsButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        !isOpen ||
        !event.target ||
        !menuRef.current ||
        toggleAccountsButtonRef.current?.contains(event.target as Node)
      ) {
        return;
      }

      if (!menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <StyledDropdownSubMenu
      ref={menuRef}
      data-testid={dataTestId}
      $active={isOpen}
      $dropdownHeight={items.length * 49}
      className="dropdown"
    >
      <button
        ref={toggleAccountsButtonRef}
        data-testid={`${dataTestId}-title`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {title}
      </button>
      <ul data-testid={`${dataTestId}-submenu`}>
        {items.map(({ displayName, link }) => (
          <li key={displayName}>
            <NavLink
              href={suffixUrlsWithConsumerType(link)}
              title={displayName}
              LinkComponent={LinkComponent}
            />
          </li>
        ))}
      </ul>
    </StyledDropdownSubMenu>
  );
};
