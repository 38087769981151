import { hexToRGB } from './utils/hexToRGB';
import { FLEX_PALETTE, FLEX_VARIABLES } from './themes/flex';

export const THEMES = {
  flex: {
    variables: FLEX_VARIABLES,
    palette: FLEX_PALETTE,
  },
};

export type ThemeName = keyof typeof THEMES;

/**
 * Gets the theme based on the config, and converts the values to CSS custom properties, which are
 * then applied to the root element.
 *
 * A theme such as:
 *
 * ```json
 * {
 *   "navbar": "#0070f3"
 * }
 * ```
 *
 * Will be converted to:
 *
 * ```css
 * :root {
 *   --theme-navbar: #0070f3;
 * }
 * ```
 *
 * This is used in combination with `themePalettePlugin` in
 * `packages/tailwindcss-config/src/plugins/themePalette.plugin.ts`
 */
export const themeNameToCssCustomProperties = (themeName: ThemeName) =>
  Object.entries(THEMES[themeName].variables)
    .map(([key, value]) => `--theme-${key}: ${hexToRGB(value)};`)
    .join('\n');
