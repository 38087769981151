import { useEffect, useRef, useState, useCallback } from 'react';
import { StyledAccountSwitcherToggle } from '../../AccountSwitcherDropdown/AccountSwitcherDropdown.styles';
import { Typography } from '../../../foundations/Typography/Typography';
import { cn } from '../../../utils/cn';
import { useNavBarContext } from '../NavBarContext/NavBarContext';

type LoginPromptProps = {
  dataTestId?: string;
};

export const LoginPrompt = ({ dataTestId }: LoginPromptProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const loginPromptBodyRef = useRef<HTMLDivElement>(null);
  const { accountHomeUrl } = useNavBarContext();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!isOpen || !e.target || !loginPromptBodyRef.current) return;
      if (!loginPromptBodyRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  const handleScroll = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClick);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleClick, handleScroll]);

  return (
    <div data-testid="login" className="relative" ref={loginPromptBodyRef}>
      <div className="flex flex-col items-center">
        <StyledAccountSwitcherToggle
          data-testid="login-toggle"
          $showAvatar
          $active={isOpen}
          onClick={() => setIsOpen((prev) => !prev)}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="4"
          viewBox="0 0 6 4"
          fill="none"
          className={cn('transition duration-300 ease-in', {
            'pointer-events-none opacity-0': !isOpen,
            'pointer-events-auto opacity-100': isOpen,
          })}
        >
          <path d="M3 0L6 4H0L3 0Z" fill="white" />
        </svg>
      </div>
      <div
        data-testid={dataTestId}
        className={cn(
          'shadow-7 absolute right-0 top-full z-50 translate-x-1/4 cursor-auto transition duration-300 ease-in lg:translate-x-0',
          {
            'pointer-events-none opacity-0': !isOpen,
            'pointer-events-auto opacity-100': isOpen,
          },
        )}
      >
        <div className="flex flex-col items-center gap-2 rounded-lg bg-white p-3">
          <Typography medium variant="body2" className="text-charcoal-500 text-nowrap w-max">
            Existing customer?
          </Typography>
          <Typography
            element="a"
            href={`${accountHomeUrl}/account`}
            title="Login"
            variant="body2"
            medium
            className="login text-blue-500 no-underline hover:!underline"
          >
            Log in
          </Typography>
        </div>
      </div>
    </div>
  );
};
