import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20 14V5.333A1.333 1.333 0 0 0 18.666 4H5.333A1.333 1.333 0 0 0 4 5.333V14zm1.885 4.125A1.333 1.333 0 0 1 20.666 20H3.334a1.334 1.334 0 0 1-1.218-1.875L4 14h16zm-11.218-.792h2.666"
    />
  </svg>
);
export const IconLaptop = memo(SvgIconLaptop);
