import { BannerType, Link } from '../Navbar.types';

export const aboutSubMenuLinks: Link[] = [
  {
    displayName: 'About us',
    link: 'https://www.raylo.com/about',
  },
  {
    displayName: 'Blog',
    link: 'https://www.raylo.com/blog',
  },
  {
    displayName: 'Sustainability',
    link: 'https://www.raylo.com/sustainability',
  },
  {
    displayName: 'Careers',
    link: 'https://www.raylo.com/careers',
  },
];

export const helpSubMenuLinks: Link[] = [
  {
    displayName: 'Help centre',
    link: 'https://help.raylo.com/',
  },
  {
    displayName: 'Contact us',
    link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
  },
];

export const categoryForUrl = (category: string) => {
  return `/products?category=${category.toLowerCase()}`;
};

export const loggedInMenuItems = (activeAccount: string, accountStatus?: BannerType) => [
  {
    icon: 'my-tech',
    name: 'My tech',
    link: `/account/overview/${activeAccount}`,
    badge: accountStatus,
  },
  {
    icon: 'account-details',
    name: 'Account details',
    link: `/account/${activeAccount}/your-account`,
  },
  {
    icon: 'referrals',
    name: 'Referrals',
    link: `/account/${activeAccount}/refer-a-friend`,
  },
];
