import { SearchField } from '../../SearchField/SearchField';

type NavBarFakeSearchFieldProps = {
  onClick: (event: React.MouseEvent) => void;
};

export const NavBarFakeSearchField = ({ onClick }: NavBarFakeSearchFieldProps) => {
  return (
    <SearchField
      value=""
      onChange={() => {}}
      wrapperProps={{ onClick: onClick }}
      placeholder="Search for products, brands..."
    />
  );
};
