import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconReferrals = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M.75 12.748a11.25 11.25 0 1 0 22.5 0 11.25 11.25 0 0 0-22.5 0" />
      <path d="M15 9.25a2.25 2.25 0 0 0-4.5 0V16A1.5 1.5 0 0 1 9 17.5h6M9 13h4.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 .748h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export const IconReferrals = memo(SvgIconReferrals);
