import { GoogleTagManager } from '@next/third-parties/google';
import { trackingDenied } from 'uibook';

export function GoogleTagManagerTracking() {
  if (trackingDenied()) {
    return;
  }

  return <GoogleTagManager gtmId="GTM-TB6BVN3" />;
}
