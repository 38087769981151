import { useMemo } from 'react';
import { NavBarWrapper, type NavDataModel, cn } from 'uibook';
import { Link } from '../../elements/Link/Link';
import { useRouter } from 'next/router';
import { consumerTypePathBase } from '@/types/consumerTypes';
import { getAlternateConsumerUrl } from '@/utils/urls.utils';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { useIntercom } from 'react-use-intercom';
import { useRayloCookiesContext } from '@/hooks/useRayloCookiesContext';
import { track } from '@/integrations/segment/segmentTracking';

type GlobalNavBarProps = {
  navData: NavDataModel;
};

const GlobalNavBar = ({ navData }: GlobalNavBarProps) => {
  const { consumerType } = useConsumerTypeContext();
  const {
    isMobileApp,
    accountSwitcherData,
    hasLoggedInCustomer,
    activeAccount,
    bannerToShow,
    isUpgrading,
  } = useCustomerContext();
  const { shutdown } = useIntercom();
  const { setDomainCookie, removeDomainCookie } = useRayloCookiesContext();
  const router = useRouter();

  const loggedInData = useMemo(() => {
    if (hasLoggedInCustomer) {
      const onLogout = () => {
        if (window?.Intercom) {
          shutdown();
        }
        window.location.assign(`${process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}/logout`);
      };

      const addBusinessAccountOnClick = () => {
        setDomainCookie('checkoutContext', 'ADD_NEW_BUSINESS');
        router.push('/business/products');
      };

      return {
        onLogout,
        addBusinessAccountOnClick,
        activeAccount: activeAccount ?? accountSwitcherData?.accounts[0],
        name: accountSwitcherData?.customerName ?? '',
        email: accountSwitcherData?.email ?? '',
        accounts: accountSwitcherData?.accounts ?? [],
        defaultAccount: activeAccount ?? accountSwitcherData?.accounts[0],
      };
    }
    return null;
  }, [hasLoggedInCustomer, activeAccount, accountSwitcherData, router, setDomainCookie, shutdown]);

  /**
   * Don't render the NavBar if the user is on the recommendations page (in reality, users should
   * only see the recommendations page if they are logged in, but put this extra check here for
   * local dev purposes).
   */
  if (['/recommendations', '/business/recommendations'].includes(router.asPath)) {
    return null;
  }

  const toggleUrl = getAlternateConsumerUrl(consumerType, router.asPath);

  if (isMobileApp) {
    return;
  }

  return (
    <div
      className={cn('bg-tv-navbar relative', {
        'h-[160px] lg:h-[154px]': loggedInData,
        'h-[208px] sm:h-[188px] lg:h-[190px]': !loggedInData,
      })}
    >
      <NavBarWrapper
        app="products"
        accountHomeUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}
        productsUrl={consumerTypePathBase[consumerType]}
        navData={navData}
        LinkComponent={Link}
        consumerType={consumerType}
        toggleUrl={toggleUrl}
        loggedInData={loggedInData}
        bannerToShow={bannerToShow}
        isUpgrading={isUpgrading}
        removeDomainCookie={removeDomainCookie}
        searchClientConfig={{
          apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_KEY!,
          host: process.env.NEXT_PUBLIC_TYPESENSE_NODE!,
        }}
        router={{
          push: router.push,
        }}
        track={track}
      />
    </div>
  );
};

export default GlobalNavBar;
