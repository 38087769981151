import { gql } from '../generated';

export const GET_CUSTOMER_DATA = gql(`
  query GetCustomerDataWithPreApprovalAndUpgrades {
  customer {
    id
    firstName
    lastName
    email
    organizations {
      id
      name
    }
    orders {
      id
      organization {
        id
      }
      items {
        id
        subscription {
          id
          arrearsAmount {
            valueInSubunit
          }
          upgrade {
            eligible
            checkout {
              id
              token
            }
          }
          activeAsset {
            id
            variant {
              id
              product {
                id
                displayName
              }
            }
          }
        }
      }
    }
    preApproval {
      checkout {
        id
        token
      }
      recurringTaxableAmount {
        valueAfterTax {
          formattedValue
          value
        }
        valueBeforeTax {
          formattedValue
          value
        }
      }
      successful
    }
  }
}`);
