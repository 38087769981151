import { px2Rem } from '../../utils/px2Rem';

const shadowElevationsList = [1, 2, 3, 4, 5, 6, 7] as const;

type ShadowElevation = (typeof shadowElevationsList)[number];

type ShadowElevationStyle = [number, number, number, number, string];

type ShadowElevations = Record<ShadowElevation, ShadowElevationStyle>;

const shadowElevations: ShadowElevations = {
  1: [0, 1, 2, 0, 'rgba(0, 0, 0, 0.05)'],
  2: [0, 1, 3, 0, 'rgba(0, 0, 0, 0.10)'],
  3: [0, 4, 6, -1, 'rgba(0, 0, 0, 0.10)'],
  4: [0, 10, 15, -3, 'rgba(0, 0, 0, 0.10)'],
  5: [0, 20, 25, -5, 'rgba(0, 0, 0, 0.10)'],
  6: [0, 25, 50, -12, 'rgba(0, 0, 0, 0.25)'],
  7: [0, 4, 24, 0, 'rgba(0, 0, 0, 0.10)'],
};

/** Reverse the shadow elevations, essentially invert the 2nd item in the array */
const shadowReverseElevations = Object.entries(shadowElevations).reduce((acc, [key, value]) => {
  const clonedValue: ShadowElevationStyle = [...value];
  clonedValue[1] = clonedValue[1] * -1;
  acc[parseInt(key) as ShadowElevation] = clonedValue;
  return acc;
}, {} as ShadowElevations);

/**
 * Returns a CSS `box-shadow` value based on the provided elevation and optional reverse flag.
 *
 * @deprecated This component has been replaced with the `.shadow-*` TailwindCSS className.
 * @example
 *   `box-shadow: ${cssShadow(3)};`;
 *
 * @param elevation - The elevation value to calculate the shadow for.
 * @param reverse - Optional flag to reverse the shadow elevations.
 * @returns The calculated `box-shadow` value.
 */
export const cssShadow = (elevation: ShadowElevation, reverse?: boolean) => {
  /** Get the elevations map */
  const elevations = reverse ? shadowReverseElevations : shadowElevations;
  /** Loop through the array and convert to a valid `box-shadow` value */
  return elevations[elevation]
    .map((value) => {
      // rgba value
      if (typeof value === 'string') {
        return value;
      }
      // px value
      return px2Rem(value);
    })
    .join(' ');
};
