import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLeases = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M21 15.261H3v-9.75a1.5 1.5 0 0 1 1.5-1.5H12m11.121 15.891a1.5 1.5 0 0 1-1.37 2.11H2.25a1.5 1.5 0 0 1-1.372-2.11L3 15.262h18zm-12.621-.89h3" />
      <path d="M17.4 1.011h4.2s1.4 0 1.4 1.375v8.25s0 1.376-1.4 1.376h-4.2s-1.4 0-1.4-1.376v-8.25s0-1.375 1.4-1.375m-1.4 8h7" />
    </g>
  </svg>
);
export const IconLeases = memo(SvgIconLeases);
