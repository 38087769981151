import { useHits } from 'react-instantsearch';
import { IconArrowRight } from 'uibook-icons/solid/IconArrowRight';
import { Typography } from '../../foundations/Typography/Typography';
import { ProductCard } from '../ProductCard/ProductCard';
import { formatDisplayPrice } from '../../utils/formatDisplayPrice';
import { getReactImageProps } from '../../utils/images';
import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { consumerTypeMoneyField } from '../../types/consumerTypes.types';
import { SearchProduct } from '../../types/search.types';
import { Button } from '../Button/Button';
import { useGlobalSearchTracking } from './hooks/useGlobalSearchTracking';

export const GlobalSearchProductCards = () => {
  const {
    LinkComponent,
    handleCloseSearchOverlay,
    consumerType,
    consumerIsBusiness,
    getSearchQueryUrl,
    productsUrl,
    loggedInData,
  } = useNavBarContext();
  const { trackSearchEvent } = useGlobalSearchTracking();
  const { items, results } = useHits<SearchProduct>();

  const Link = LinkComponent || 'a';

  if (!results?.query || results.query === '*' || items.length === 0) {
    return null;
  }

  const currentQuery = results.query;

  const priceField = consumerTypeMoneyField[consumerType];

  const handleClickSeeAll = () => {
    trackSearchEvent(currentQuery);
    handleCloseSearchOverlay();
  };

  return (
    <div
      className="bg-charcoal-100 flex flex-shrink-0 flex-grow flex-col gap-6 px-6 py-8"
      data-testid="GlobalSearchProductCards"
    >
      <Typography bold>Products</Typography>
      {items.map((item) => (
        <Link
          key={item.id}
          href={`${productsUrl}/products/${item.variantSlug}`}
          className="flex gap-4"
          onClick={handleCloseSearchOverlay}
          data-testid={`GlobalSearchProductCards-${item.id}`}
        >
          <ProductCard
            product={item}
            displayPrice={formatDisplayPrice(item.lowestMonthlyCost[priceField])}
            priceRenderState={loggedInData ? 'hidden' : 'displayed'}
            priceSuffix={consumerIsBusiness ? 'excl. VAT' : undefined}
            getImageProps={getReactImageProps}
          />
        </Link>
      ))}
      <div>
        <Button
          as={Link}
          variant="text"
          color="blue"
          size="large"
          href={getSearchQueryUrl(currentQuery)}
          onClick={handleClickSeeAll}
          endIcon={<IconArrowRight />}
          data-testid="GlobalSearchProductCards-SeeAll"
        >
          <span>
            {`See all “`}
            <strong className="font-bold">{currentQuery}</strong>
            {`”`}
          </span>
        </Button>
      </div>
    </div>
  );
};
