import type { NavDataModel } from '../../../types/navData.types';
import { NavLink, BurgerNav } from '../utils/utilComponents';
import { StyledDesktopItems } from '../NavBarWrapper/NavBarWrapper.styles';
import { Dispatch, SetStateAction } from 'react';
import { SideNavType } from '../NavBarContext/NavBarContext';
import RayloModal from '../../../designSystem/RayloModal';
import { Typography } from '../../../foundations/Typography/Typography';
import { categoryForUrl } from '../utils/utils';

type DesktopLinksProps = {
  navData: NavDataModel;
  LinkComponent?: React.ElementType;
  sideNavOpen: SideNavType;
  upgradeExitModalOpen: boolean;
  setUpgradeExitModalOpen: Dispatch<SetStateAction<boolean>>;
  exitUpgrade: () => void;
  handleUpgradeExitModalOpen: (name: string) => void;
  productsUrl: string;
  displayUpgradeModal: boolean;
  setSideNavOpen: Dispatch<SetStateAction<SideNavType>>;
};

export const DesktopLinks = ({
  navData,
  LinkComponent,
  setUpgradeExitModalOpen,
  upgradeExitModalOpen,
  sideNavOpen,
  exitUpgrade,
  handleUpgradeExitModalOpen,
  productsUrl,
  displayUpgradeModal,
  setSideNavOpen,
}: DesktopLinksProps) => (
  <>
    {upgradeExitModalOpen && (
      <RayloModal
        modalOpen
        title="Exit your Upgrade?"
        primaryButtonText="Exit"
        primaryButtonOnClick={exitUpgrade}
        secondaryButtonText="Continue"
        handleCloseModal={() => setUpgradeExitModalOpen(false)}
        secondaryButtonOnClick={() => setUpgradeExitModalOpen(false)}
      >
        <Typography bold className="mb-4">
          Any upgrade selections will be lost.
        </Typography>
        <Typography>You can restart your upgrade at any time.</Typography>
      </RayloModal>
    )}
    <StyledDesktopItems>
      <div
        data-testid="burger-desktop"
        className="burger-desktop"
        onClick={() =>
          displayUpgradeModal ? handleUpgradeExitModalOpen('burger-nav') : setSideNavOpen('product')
        }
      >
        <BurgerNav sideNavOpen={sideNavOpen} />
        <p>Menu</p>
      </div>
      <div className="divider" />
      <div className="category-links-desktop">
        {navData.categories.map(({ name }) => (
          <NavLink
            dataTestId={`desktop-links-${name}`}
            handleUpgradeExitModal={displayUpgradeModal ? handleUpgradeExitModalOpen : undefined}
            href={`${productsUrl}${categoryForUrl(name.toLowerCase())}`}
            title={name}
            LinkComponent={LinkComponent}
            key={name}
          />
        ))}
      </div>
    </StyledDesktopItems>
  </>
);
