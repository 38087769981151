import type { ThemePalette, ThemeVariableSlots } from '../theming.types';

/** PlayStation Flex */
export const FLEX_PALETTE: ThemePalette = {
  orange: '#CD3D0F',
  'orange-tint': '#992E0B',
  blue: '#00439C',
  'blue-light': '#0070CC',
  'blue-light-tint': '#005499',
  'grey-light': '#ADADAD',
  'grey-dark': '#6D6D6D',
  black: '#000000',
};

export const FLEX_VARIABLES: ThemeVariableSlots = {
  navbar: FLEX_PALETTE.black,
  'banner-button-with-icon': FLEX_PALETTE.blue,
};
