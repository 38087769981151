import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { getPaletteColorByDotNotation, palette, PaletteColorKeys } from '../../colors';
import { ASSETS_SVGS_BASE_URL, CSS_FONT_WEIGHT } from '../../constants';
import { cssShadow } from '../../foundations/Shadow/Shadow.styles';

const accountCircle = (
  $backgroundColor: PaletteColorKeys,
  $color: PaletteColorKeys,
  $letter?: string,
  $showAvatar?: boolean,
) => `
  content: ${$showAvatar ? '""' : `'${$letter}'`};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2Rem(32)};
  height: ${px2Rem(32)};
  background-color: ${getPaletteColorByDotNotation($backgroundColor)};
  border-radius: ${px2Rem(100)};
  color: ${getPaletteColorByDotNotation($color)};
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  font-size: ${px2Rem(14)};
  flex-shrink: 0;
  ${
    $showAvatar &&
    `
      background-image: url('${ASSETS_SVGS_BASE_URL}/avatar-icon.svg');
      background-size: ${px2Rem(16)};
      background-repeat: no-repeat;
      background-position: center;
    `
  }
`;

export const StyledAccountSwitcherToggle = styled.button<{
  $active: boolean;
  $letter?: string;
  $showAvatar?: boolean;
}>`
  ${({ $active, $letter, $showAvatar }) => `
    border: none;
    background: transparent;
    padding: 0;
    border-radius: ${px2Rem(100)};
    background-color: ${palette.blue[$active ? 400 : 500]};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${palette.blue[400]};

    &:before {
      ${accountCircle('white', 'blue.500', $letter, $showAvatar)};
    }

    @media (min-width: 360px) {
      &:after {
        height: ${px2Rem(8)};
        width: ${px2Rem(30)};
        content: '';
        display: block;
        background-image: url('${ASSETS_SVGS_BASE_URL}/chevron-down-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: ${px2Rem(10)};
        transition: transform 0.3s ease;
        transform: rotate(${$active ? '180deg' : '0deg'});
        transform-origin: center;
      }
    }

    &:hover {
      background-color: ${palette.blue[400]};
    }
`};
`;

export const StyledAccountsMenu = styled.div<{
  $active: boolean;
  $height: number;
}>`
  ${({ $active, $height }) => `
    display: block;
    transition: height 0.3s ease;
    z-index: 1000;
    background-color: ${palette.white};
    position: absolute;
    bottom: ${px2Rem(24)};
    top: calc(100% - ${px2Rem(24)});
    box-shadow: ${cssShadow(6)};
    overflow: hidden;
    height: ${px2Rem($active ? $height : 0)};
    width: calc(100% - ${px2Rem(24)});
    bottom: ${px2Rem(24)};
    left: ${px2Rem(12)};
    border-radius: 0;
  `};
`;

export const StyledAccountsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid ${palette.charcoal[200]};
`;

export const StyledAccountListItem = styled.li<{
  $isBusiness: boolean;
  $active: boolean;
  $letter: string;
}>`
  ${({ $isBusiness, $active, $letter }) => `
    cursor: pointer;
    padding: ${$active ? `${px2Rem(16)} ${px2Rem(40)} ${px2Rem(16)} ${px2Rem(16)}` : px2Rem(16)};
    display: flex;
    align-items: center;
    gap: ${px2Rem(12)};
    ${
      $active &&
      `
      background-color: ${palette.blue[100]};
      background-image: url("${ASSETS_SVGS_BASE_URL}/charcoal-500-tick.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - ${px2Rem(16)}) 50%;
      background-size: ${px2Rem(24)};
    `
    };

    &:before {
      ${accountCircle($active ? 'blue.500' : 'charcoal.100', $active ? 'white' : 'charcoal.500', $letter)};
    }

    &:hover {
      background-color: ${$active ? palette.blue[100] : palette.charcoal[100]};

      &:before {
        background-color: ${$active ? palette.blue[500] : palette.charcoal[200]};
      }
    }

    ${
      $isBusiness &&
      `
      &:after {
        content: 'Business';
        text-align: center;
        font-size: ${px2Rem(12)};
        font-weight: ${CSS_FONT_WEIGHT.vars.regular};
        line-height: normal;
        padding: ${px2Rem(4)} ${px2Rem(6)};
        border-radius: ${px2Rem(4)};
        background-color: ${palette.pink[300]};
      }
    `
    };
  `}
`;
