import { consumerTypePathBase, ConsumerTypesEnum } from '@/types/consumerTypes';

/**
 * Prefixes the URL path based on the consumer type.
 *
 * @param consumerType - The type of consumer.
 * @param path - The URL path to be prefixed.
 * @returns The prefixed URL path.
 */
export const prefixUrlPathByConsumerType = (
  consumerType: ConsumerTypesEnum,
  path: `/${string}`,
) => {
  return `${consumerTypePathBase[consumerType]}${path}`;
};

/**
 * Generates an alternate URL for a given consumer type.
 *
 * @param currentConsumerType - The current type of consumer (e.g., BUSINESS or PERSONAL). If the
 *   current consumer type is BUSINESS, the returned alternate consumer type will be PERSONAL.
 * @param pathname - The current URL path.
 * @returns The modified URL path for the alternate consumer type.
 */
export const getAlternateConsumerUrl = (
  currentConsumerType: ConsumerTypesEnum,
  pathname: string,
) => {
  if (currentConsumerType === ConsumerTypesEnum.BUSINESS) {
    return pathname.replace(
      consumerTypePathBase[ConsumerTypesEnum.BUSINESS],
      consumerTypePathBase[ConsumerTypesEnum.PERSONAL],
    );
  }

  return prefixUrlPathByConsumerType(
    ConsumerTypesEnum.BUSINESS,
    pathname as Parameters<typeof prefixUrlPathByConsumerType>[1],
  );
};
