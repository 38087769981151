import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconHowItWorks = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="m22.776 13.963-3.206-3.558-2.517 4.074" />
      <path d="M19.554 10.45a9.33 9.33 0 0 1-6.631 11.387M6.269 1.476l-1.788 4.44 4.764.474" />
      <path d="M4.494 5.906a9.37 9.37 0 0 1 13.176.964M2.84 22.52l4.738-.693-1.995-4.354" />
      <path d="M7.512 21.788A9.31 9.31 0 0 1 1.75 9.955" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export const IconHowItWorks = memo(SvgIconHowItWorks);
