export type Account = {
  id: string | null;
  name: string;
  isBusiness: boolean;
  onClick: () => void;
};

export type CategoryItem = {
  name: string;
  makes: {
    name: string | null | undefined;
    models: {
      displayName: string | null | undefined;
      slug: string | undefined;
    }[];
  }[];
};

export type NavDataModel = {
  categories: CategoryItem[];
};

export type LoggedInData = {
  accounts: Account[];
  defaultAccount?: Account;
  activeAccount?: Account;
  onLogout: () => void;
  name: string;
  email: string;
  addBusinessAccountOnClick: () => void;
} | null;

export type Category = {
  __typename?: 'ProductCategory';
  id: string;
  slug: string;
  displayName?: string | null;
};

export type ProductCategoriesQuery = {
  __typename?: 'Query';
  productCategories: Category[];
};

export enum VariantCondition {
  New = 'NEW',
  Refurbished = 'REFURBISHED',
}

export type ProductsQuery = {
  __typename?: 'Product';
  id: string;
  position: number;
  displayName?: string | null;
  available: boolean;
  category: { __typename?: 'ProductCategory'; id: string; displayName?: string | null };
  manufacturer: { __typename?: 'Manufacturer'; id: string; displayName?: string | null };
  variants: Array<{
    __typename?: 'Variant';
    id: string;
    position: number;
    displayName?: string | null;
    slug: string;
    available: boolean;
  }>;
} | null;

export type ProductsWithDefaultVariant = {
  defaultVariant: {
    id: string;
    displayName: string;
    slug: string;
    make?: string;
    position: number;
    available: boolean;
  };
  displayName: string | null | undefined;
  catDisplayName: string;
  position: number;
  make: string;
  variantSlug: string;
};
