/**
 * `ASSETS_BASE_URL` and `ASSETS_IMAGES_CDN_BASE_URL` should not include a trailing slash, so that
 * they can be used for preconnect tags to improve performance This points to the R2 `assets` bucket
 * on Cloudflare.
 *
 * The `ASSETS_IMAGES_CDN_BASE_URL` is used for images that are served via IMGIX, which in-turn
 * points to the `static-assets` R2 bucket on Cloudflare.
 */
export const ASSETS_BASE_URL = 'https://static-assets.raylo.com';
export const ASSETS_FONTS_BASE_URL = `${ASSETS_BASE_URL}/fonts`;
export const ASSETS_SVGS_BASE_URL = `${ASSETS_BASE_URL}/svgs`;
export const ASSETS_IMAGES_CDN_BASE_URL = 'https://raylo-r2.imgix.net';
export const ASSETS_IMAGES_BASE_URL = `${ASSETS_IMAGES_CDN_BASE_URL}/images`;

// CSS custom properties to be used for font-family variables
const cssFontFamilyProperties = {
  sans: '--font-family-sans' as const,
  mono: '--font-family-mono' as const,
};

// Combined object for font-family CSS custom properties and variables
export const CSS_FONT_FAMILY = {
  properties: cssFontFamilyProperties,
  vars: {
    sans: `var(${cssFontFamilyProperties.sans})` as const,
    mono: `var(${cssFontFamilyProperties.mono})` as const,
  },
};

// CSS custom properties to be used for font-weight variables
const cssFontWeightProperties = {
  regular: '--font-weight-regular' as const,
  medium: '--font-weight-medium' as const,
  bold: '--font-weight-bold' as const,
};

// Combined object for font-weight CSS custom properties and variables
export const CSS_FONT_WEIGHT = {
  properties: cssFontWeightProperties,
  vars: {
    regular: `var(${cssFontWeightProperties.regular})` as const,
    medium: `var(${cssFontWeightProperties.medium})` as const,
    bold: `var(${cssFontWeightProperties.bold})` as const,
  },
};

export const BREAKPOINTS = {
  largeDesktop: 1800 as const,
  mobileNav: 1300 as const,
  desktop: 1200 as const,
  smallDesktop: 900 as const,
  tablet: 600 as const,
  mobile: 320 as const,
};

/**
 * @todo This is just temporary whilst we incrementally migrate to Tailwind, it will be tagged as
 *   deprecated in future
 */
export const TAILWIND_BREAKPOINTS = {
  xs: 420 as const,
  sm: 640 as const,
  md: 768 as const,
  lg: 1024 as const,
  xl: 1280 as const,
  '2xl': 1536 as const,
};

export const DEFAULT_TERM_LENGTH = 12 as const;
