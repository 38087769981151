import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2 12a10 10 0 1 0 20 0 10 10 0 0 0-20 0m12.829-2.828-5.657 5.656m0-5.656 5.656 5.656"
    />
  </svg>
);
export const IconDelete = memo(SvgIconDelete);
